@import "components/_variables";
@import "components/_mixins";

//404
.cms-no-route{
  .context {
    width: 100%;
    position: absolute;
    top:15%;
    z-index: 100;
    text-align: center;

    h1{
      margin-bottom: 2rem;
    }
    h1,
    h2{
      text-align: center;
      color: color(black,base);
      font-weight: 800;
      font-size: 50px;
      z-index: 100;
    }
    h2{
      font-size: 32px;
      font-weight: 500;
    }

    a{
      background-color: color(black,base);
      padding: 10px 35px;
      display: inline-block;
      color: #ffffff;
      font-size: 2rem;
      margin-top: 2rem;
    }

  }


  .context h2{
    font-size:35px;

  }
  .context a{

  }
  .area{
    width: 100%;
    height: 50vh;
    position: relative;
  }

  .circles{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 55vh;
    overflow: hidden;
  }

  .circles li{
    position: absolute;
    display: block;
    list-style: none;
    width: 20px;
    height: 20px;
    background: transparentize(color(black,base), .4);
    animation: animate 25s linear infinite;
    bottom: -170px;

  }

  .circles li:nth-child(1){
    left: 25%;
    width: 80px;
    height: 80px;
    animation-delay: 0s;
  }


  .circles li:nth-child(2){
    left: 10%;
    width: 20px;
    height: 20px;
    animation-delay: 2s;
    animation-duration: 12s;
  }

  .circles li:nth-child(3){
    left: 70%;
    width: 20px;
    height: 20px;
    animation-delay: 4s;
  }

  .circles li:nth-child(4){
    left: 40%;
    width: 60px;
    height: 60px;
    animation-delay: 0s;
    animation-duration: 18s;
  }

  .circles li:nth-child(5){
    left: 65%;
    width: 20px;
    height: 20px;
    animation-delay: 0s;
  }

  .circles li:nth-child(6){
    left: 75%;
    width: 110px;
    height: 110px;
    animation-delay: 3s;
  }

  .circles li:nth-child(7){
    left: 35%;
    width: 150px;
    height: 150px;
    animation-delay: 7s;
  }

  .circles li:nth-child(8){
    left: 50%;
    width: 25px;
    height: 25px;
    animation-delay: 15s;
    animation-duration: 45s;
  }

  .circles li:nth-child(9){
    left: 20%;
    width: 15px;
    height: 15px;
    animation-delay: 2s;
    animation-duration: 35s;
  }

  .circles li:nth-child(10){
    left: 85%;
    width: 150px;
    height: 150px;
    animation-delay: 0s;
    animation-duration: 11s;
  }



  @keyframes animate {

    0%{
      transform: translateY(0) rotate(0deg);
      opacity: 1;
      border-radius: 0;
    }

    100%{
      transform: translateY(-1000px) rotate(720deg);
      opacity: 0;
      border-radius: 50%;
    }

  }
}
